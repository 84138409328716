
//Admin Routen
const routes =  [
    {   path: '/admin/bund',
        name: '/admin/bund',
        component: () => import(/* webpackChunkName: "Bund" */ '../../../views/admin/Bund'),
        meta: {requiresAdminAuth: true, authLevels: [4]}
    },
    {   path: '/admin/verband',
        name: '/admin/verband',
        component: () => import(/* webpackChunkName: "verband" */ '../../../views/admin/Verband'),
        meta: {requiresAdminAuth: true, authLevels: [4]}
    },
    {   path: '/admin/users',
        name: '/admin/users',
        component: () => import(/* webpackChunkName: "users" */ '../../../views/admin/Users'),
        meta: {requiresAdminAuth: true, authLevels: [4]}
    },
    {   path: '/admin/clubs',
        name: '/admin/clubs',
        component: () => import(/* webpackChunkName: "clubs" */ '../../../views/admin/Clubs'),
        meta: {requiresAdminAuth: true, authLevels: [4]}
    },
    {   path: '/admin/feedback',
        name: '/admin/feedback',
        component: () => import(/* webpackChunkName: "feedback" */ '../../../views/admin/Feedback'),
        meta: {requiresAdminAuth: true, authLevels: [4]}
    },
    {   path: '/admin/texteditor',
        name: '/admin/texteditor',
        component: () => import(/* webpackChunkName: "texteditor" */ '../../../views/admin/TextEditor'),
        meta: {requiresAdminAuth: true, authLevels: [4]}
    },
    {   path: '/admin/massmail',
        name: '/admin/massmail',
        component: () => import(/* webpackChunkName: "massmail" */ '../../../views/admin/MassMail'),
        meta: {requiresAdminAuth: true, authLevels: [4]}
    },


];

export default routes
