import {API} from "../../../axios";
import swal from "sweetalert2";

const actions = {
    getBundData({commit, rootState}){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        API.get('/admin/Bund')
            .then((resp) => {
                commit('GET_BUND', resp.data);
            })
            .catch( error => { console.log(error); });
    },
    getVerbandData({commit, rootState}){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        API.get('/admin/Verband')
            .then((resp) => {
                commit('GET_VERBAND', resp.data);
            })
            .catch( error => { console.log(error); });
    },
    getUsersData({commit, rootState}){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        API.get('/admin/Users')
            .then((resp) => {
                commit('GET_USERS', resp.data);
            })
            .catch( error => { console.log(error); });
    },
    getClubsData({commit, rootState}){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        API.get('/admin/Clubs')
            .then((resp) => {
                commit('GET_CLUBS', resp.data);
            })
            .catch( error => { console.log(error); });
    },
    getTexte({commit, rootState}){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        API.get('/admin/Texte')
            .then((resp) => {
                commit('GET_TEXTE', resp.data);
            })
            .catch( error => { console.log(error); });
    },
    getSingleTexte({commit, rootState}, TextID){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        API.get('/admin/Texte/showText', {
            params: {
                TextID
            }
            })
            .then((resp) => {
                commit('GET_SINGLETEXTE', resp.data);
            })
            .catch( error => { console.log(error); });
    },
    setSingleText({commit, rootState}, data){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        API.post('/admin/Texte/update', data)
            .then((resp) => {
                 commit('GET_SINGLETEXTE', resp.data);
            })
            .catch( error => { console.log(error); });
    },
    getFeedback({commit, rootState}){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        API.get('/admin/feedbacks')
            .then((resp) => {
                commit('GET_FEEDBACK', resp.data);
            })
            .catch( error => { console.log(error); });
    },
    async setSendMail({rootState}, Data){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/admin/MailtoUser', Data)
            .then(() => {
                swal.fire('Nachricht gesendet', 'Die Nachricht wurde erfolgreich versendet!', 'success');
            })
            .catch(error => {
                console.log(error.response);
            });

    },


};

export default actions;
